import React from 'react';
import styled from 'styled-components';
import { Container, WrapperContainer } from '../../components/Styles';
import covid1 from '../../images/covid1.jpg';
import covid2 from '../../images/covid2.jpg';
import covid3 from '../../images/covid3.jpg';
import covid4 from '../../images/covid4.jpg';

const Image = styled.img`
    width: 100%;
    height: auto;
`;

const Title = styled.span`
    font-size: 2rem;
    font-weight: bold;
    @media(max-width: 768px) {
        font-size: 1.5rem;
    }
`

const CovidGuidelinesPage = () => {
    return (
        <div>
            <WrapperContainer>
                <Container>
                    <div className="p-3 text-center">
                        <Title>Covid-19 Guidelines For Students</Title>
                    </div>
                    <Image
                        src={covid1}
                        alt="banner"
                    />
                    <Image
                        src={covid2}
                        alt="banner"
                    />
                    <Image
                        src={covid3}
                        alt="banner"
                    />
                    <Image
                        src={covid4}
                        alt="banner"
                    />
                </Container>
            </WrapperContainer>
        </div>
    );
}

export default CovidGuidelinesPage;