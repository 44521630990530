import React from 'react';
import { Switch, Route } from 'react-router-dom'
import LandingPage from './pages/LandingPage';
import CovidGuidelinesPage from './pages/CovidGuidelinesPage';
import AdmissionsPage from './pages/AdmissionsPage';
import Header from './components/Header'
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

class App extends React.Component {
    render() {
        return (
            <ScrollToTop>
                <div>
                    <Header />
                    <Switch>
                        <Route
                            path="/admissions"
                            component={AdmissionsPage}
                        />
                        <Route
                            path="/covid-guidelines"
                            component={CovidGuidelinesPage}
                        />
                        <Route
                            path="/"
                            component={LandingPage}
                        />
                    </Switch>
                    <Footer />
                </div>
            </ScrollToTop>
        )
    }
}


export default App;
