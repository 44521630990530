import React, { useReducer, useEffect, useState } from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { FlexWrapper } from './Styles'

const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
const UPDATE_ERRORS = 'UPDATE_ERRORS';
const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
const CLEAR_STATE = 'CLEAR_STATE';


const FormMessage = styled.div`
    width: 100%;
    margin: 1rem 0;
    padding: 0.25rem 0;
    text-align: center;
    font-weight: bold;

`;

const Input = styled.input`
    width: ${(props) => props.width ? props.width : '90%'};
    height: 40px;
    padding: 0 0.25rem;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 2px solid #CCC;
    outline: none;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    :focus {
        border-bottom: 2px solid #388E3C;
    }
    @media(max-width: 768px) {
        width: ${(props) => props.width ? props.width : '100%'};
    }
`;
const Textarea = styled.textarea`
    width: ${(props) => props.width ? props.width : '90%'};
    padding: 0.5rem;
    border: 2px solid #CCC;
    outline: none;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    :focus {
        border: 2px solid #388E3C;
    }
    @media(max-width: 768px) {
        width: ${(props) => props.width ? props.width : '100%'};
    }
`;

const Label = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`;

const Submit = styled.button`
    background-color: #4CAF50;
    border-radius: 0;
    width: 150px;
    height: 40px;
    color: #fff;
    border: none;
    font-weight: bold;
    outline: none;
`;

const Error = styled.p`
    font-size: 0.9rem;
    color: red;
    margin-left: 1rem;
`

const initialState = {
    formData: {
        fullName: null,
        dateOfBirth: null,
        ageYears: null,
        ageMonths: null,
        gender: null,
        motherTongue: null,
        nationality: null,
        religion: null,
        secondLanguage: null,
        fatherName: null,
        fatherOccupation: null,
        motherName: null,
        phoneNo: null,
        alternateNo: null,
        email: null,
        address: null,
        pincode: null,
        lastSchool: null,
        uid: null,
        stream: null,
        illness: null
    },
    blurredElements: {
        fullName: null,
        dateOfBirth: null,
        gender: null,
        ageYears: null,
        ageMonths: null,
        motherTongue: null,
        nationality: null,
        religion: null,
        secondLanguage: null,
        fatherName: null,
        fatherOccupation: null,
        motherName: null,
        phoneNo: null,
        address: null,
        pincode: null,
        lastSchool: null,
        stream: null,
        uid: null
    },
    errors: {
        fullName: null,
        dateOfBirth: null,
        gender: null,
        ageYears: null,
        ageMonths: null,
        motherTongue: null,
        nationality: null,
        religion: null,
        secondLanguage: null,
        fatherName: null,
        fatherOccupation: null,
        motherName: null,
        phoneNo: null,
        address: null,
        pincode: null,
        lastSchool: null,
        stream: null,
        uid: null
    },
    formMessage: null,
};


function updateFormData(changedData) {
    return {
        type: UPDATE_FORM_DATA,
        changedData
    };
}

function updateBlurredElement(element) {
    return {
        type: UPDATE_BLURRED_ELEMENT,
        element
    };
}

function setAllElementsBlurred() {
    return {
        type: SET_ALL_ELEMENTS_BLURRED,
    };
}

function updateErrors(errors) {
    return {
        type: UPDATE_ERRORS,
        errors
    };
}

function setFormMessage(message) {
    return {
        type: SET_FORM_MESSAGE,
        message
    };
}

function clearState() {
    return {
        type: CLEAR_STATE
    };
}

function reducer(state, action) {
    switch (action.type) {

        case UPDATE_FORM_DATA: {
            const formData = Object.assign({}, state.formData);
            const changedData = action.changedData;
            formData[changedData.name] = changedData.value;
            return { ...state, formData }
        }

        case UPDATE_BLURRED_ELEMENT: {
            const blurredElements = Object.assign({}, state.blurredElements);
            const element = action.element;
            blurredElements[element] = true;
            return { ...state, blurredElements }
        }
        case UPDATE_ERRORS:
            return { ...state, errors: action.errors }

        case SET_ALL_ELEMENTS_BLURRED: {
            const blurredElements = {
                fullName: true,
                dateOfBirth: true,
                gender: true,
                ageYears: true,
                ageMonths: true,
                motherTongue: true,
                nationality: true,
                religion: true,
                secondLanguage: true,
                fatherName: true,
                fatherOccupation: true,
                motherName: true,
                phoneNo: true,
                address: true,
                pincode: true,
                lastSchool: true,
                stream: true,
                uid: true
            };
            return { ...state, blurredElements }
        }

        case SET_FORM_MESSAGE:
            return { ...state, formMessage: action.message }

        case CLEAR_STATE:
            return initialState;
        default:
            return state;
    }
}


const AdmissionForm = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { formData, blurredElements, errors, formMessage } = state;
    const [isButtonDisabled, disableButton] = useState(false);

    useEffect(() => {
        getErrors();
        clearFormMessage();
    }, [formData, blurredElements]) // eslint-disable-line react-hooks/exhaustive-deps

    function onInputChange(e) {
        const changedData = {
            name: e.target.name,
            value: e.target.value
        };
        dispatch(updateFormData(changedData))
    }

    function handleBlur(element) {
        dispatch(updateBlurredElement(element))
    }

    function onFormSubmit(e) {
        e.preventDefault();
        const validation = validateForm(formData);
        const validated = !Object.keys(validation).some((i) => validation[i]);
        if (validated) {
            saveData()
        } else {
            dispatch(setAllElementsBlurred());
        }
    }

    async function saveData() {
        try {
            disableButton(true)
            dispatch(setFormMessage('saving'));
            const url = '/api/v1/admission';
            const options = {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }
            const response = await fetch(url, options);
            const parsedResponse = await response.json();
            if (response.status === 200 && parsedResponse.success) {
                dispatch(clearState())
                dispatch(setFormMessage('success'));
            } else {
                dispatch(setFormMessage('error'));
            }
            disableButton(false)
        } catch (err) {
            console.log('on save error', err)
            dispatch(setFormMessage('error'));
            disableButton(false)
        }
    }

    function getErrors() {
        const validationErrors = validateForm(formData);
        const errors = Object.keys(validationErrors).reduce((acc, curr) => {
            if (validationErrors[curr] && blurredElements[curr]) {
                acc[curr] = true;
            } else {
                acc[curr] = false;
            }
            return acc;
        }, {});
        dispatch(updateErrors(errors));
    }

    function clearFormMessage() {
        if (formMessage !== null) {
            if (Object.keys(formData).some((i) => formData[i] !== null) || Object.keys(blurredElements).some((i) => blurredElements[i])) {
                dispatch(setFormMessage(null));
            }
        }
    }


    function validateForm(data) {
        return {
            fullName: !data.fullName || data.fullName.length < 1,
            dateOfBirth: !data.dateOfBirth || data.dateOfBirth.length < 1,
            ageYears: !data.ageYears || data.ageYears.length < 1,
            ageMonths: !data.ageMonths || data.ageMonths.length < 1,
            motherTongue: !data.motherTongue || data.motherTongue.length < 1,
            nationality: !data.nationality || data.nationality.length < 1,
            religion: !data.religion || data.religion.length < 1,
            secondLanguage: !data.secondLanguage || data.secondLanguage.length < 1,
            fatherName: !data.fatherName || data.fatherName.length < 1,
            fatherOccupation: !data.fatherOccupation || data.fatherOccupation.length < 1,
            motherName: !data.motherName || data.motherName.length < 1,
            phoneNo: !data.phoneNo || data.phoneNo.length < 1,
            address: !data.address || data.address.length < 1,
            pincode: !data.pincode || data.pincode.length < 1,
            lastSchool: !data.lastSchool || data.lastSchool.length < 1,
            stream: !data.stream || data.stream.length < 1,
            uid: !data.uid || data.uid.length < 1,
        };
    }

    function renderFormMessage() {
        if (formMessage) {
            let message;
            let fontColor;
            if (formMessage === 'success') {
                message = 'Your application has been submitted successfully'
                fontColor = '#28a745';
            } else if (formMessage === 'error') {
                message = 'Application submit failed. Something went wrong. Please try again later'
                fontColor = '#e5002d';
            } else {
                message = 'Submitting...'
                fontColor = '#aaa';
            }
            return <FormMessage>
                <span style={{ color: fontColor }}>{message}</span>
            </FormMessage>
        }
        return null;
    }

    return (
        <div className="mt-5 mb-5">
            {renderFormMessage()}
            <form onSubmit={onFormSubmit}>
                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <Label>Full Name</Label>
                    </Col>
                    <Col md={8} xs={12}>
                        <Input
                            type="text"
                            name="fullName"
                            placeholder="Full name of the candidate"
                            value={formData.fullName || ''}
                            onChange={onInputChange}
                            onBlur={() => handleBlur('fullName')}
                        />
                        {
                            errors && errors.fullName
                                ? <Error>
                                    * This field cannot be empty
                                    </Error>
                                : null
                        }
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <Label>Date of Birth</Label>
                    </Col>
                    <Col md={8} xs={12}>
                        <Input
                            width={window.innerWidth >= '769' ? '45%' : '90%'}
                            type="date"
                            name="dateOfBirth"
                            placeholder="Birthday of the candidate"
                            value={formData.dateOfBirth || ''}
                            onChange={onInputChange}
                            onBlur={() => handleBlur('dateOfBirth')}
                        />
                        {
                            errors && errors.dateOfBirth
                                ? <Error>
                                    * This field cannot be empty
                                    </Error>
                                : null
                        }
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <Label>Age on the date of application</Label>
                    </Col>
                    <Col md={8} xs={12}>
                        <Input
                            width="80px"
                            type="number"
                            name="ageYears"
                            placeholder="Years"
                            value={formData.ageYears || ''}
                            onChange={onInputChange}
                            onBlur={() => handleBlur('ageYears')}
                        />
                        <Input
                            width="80px"
                            type="number"
                            name="ageMonths"
                            placeholder="Months"
                            value={formData.ageMonths || ''}
                            onChange={onInputChange}
                            onBlur={() => handleBlur('ageMonths')}
                            className="ml-3"
                        />
                        {
                            errors && (errors.ageYears || errors.ageMonths)
                                ? <Error>
                                    * This field cannot be empty
                                    </Error>
                                : null
                        }
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <Label>Gender</Label>
                    </Col>
                    <Col md={8} xs={12}>
                        <FlexWrapper style={{ height: '40px' }}>
                            <span>
                                <input
                                    type="radio"
                                    name="gender"
                                    className="mr-1 ml-0"
                                    value="Male"
                                    checked={formData.gender && formData.gender === 'Male'}
                                    onChange={onInputChange}
                                    onBlur={() => handleBlur('gender')}
                                /> Male
                            </span>
                            <span className="ml-5">
                                <input
                                    type="radio"
                                    name="gender"
                                    className="mr-1"
                                    value="Female"
                                    checked={formData.gender && formData.gender === 'Female'}
                                    onChange={onInputChange}
                                    onBlur={() => handleBlur('gender')}
                                /> Female
                            </span>
                        </FlexWrapper>
                        {
                            errors && errors.gender
                                ? <Error>
                                    * This field cannot be empty
                                    </Error>
                                : null
                        }
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <Label>Mother Tongue</Label>
                    </Col>
                    <Col md={8} xs={12}>
                        <Input
                            type="text"
                            name="motherTongue"
                            placeholder="Mother tongue of the candidate"
                            value={formData.motherTongue || ''}
                            onChange={onInputChange}
                            onBlur={() => handleBlur('motherTongue')}
                        />
                        {
                            errors && errors.motherTongue
                                ? <Error>
                                    * This field cannot be empty
                                    </Error>
                                : null
                        }
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <Label>Nationality</Label>
                    </Col>
                    <Col md={8} xs={12}>
                        <Input
                            type="text"
                            name="nationality"
                            placeholder="Nationality of the candidate"
                            value={formData.nationality || ''}
                            onChange={onInputChange}
                            onBlur={() => handleBlur('nationality')}
                        />
                        {
                            errors && errors.nationality
                                ? <Error>
                                    * This field cannot be empty
                                    </Error>
                                : null
                        }
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <Label>Religion</Label>
                    </Col>
                    <Col md={8} xs={12}>
                        <Input
                            type="text"
                            name="religion"
                            placeholder="Religion of the candidate"
                            value={formData.religion || ''}
                            onChange={onInputChange}
                            onBlur={() => handleBlur('religion')}
                        />
                        {
                            errors && errors.religion
                                ? <Error>
                                    * This field cannot be empty
                                    </Error>
                                : null
                        }
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <Label>
                            Language that will be taken as second language
                        </Label>
                    </Col>
                    <Col md={8} xs={12}>
                        <FlexWrapper style={{ height: '40px' }}>
                            <span>
                                <input
                                    type="radio"
                                    name="secondLanguage"
                                    className="mr-1"
                                    value="Hindi"
                                    checked={formData.secondLanguage && formData.secondLanguage === 'Hindi'}
                                    onChange={onInputChange}
                                    onBlur={() => handleBlur('religion')}
                                /> Hindi
                            </span>
                            <span className="ml-md-5 ml-3">
                                <input
                                    type="radio"
                                    name="secondLanguage"
                                    className="mr-1"
                                    value="Nepali"
                                    checked={formData.secondLanguage && formData.secondLanguage === 'Nepali'}
                                    onChange={onInputChange}
                                    onBlur={() => handleBlur('secondLanguage')}
                                /> Nepali
                            </span>
                            <span className="ml-md-5 ml-3">
                                <input
                                    type="radio"
                                    name="secondLanguage"
                                    className="mr-1"
                                    value="Bengali"
                                    checked={formData.secondLanguage && formData.secondLanguage === 'Bengali'}
                                    onChange={onInputChange}
                                    onBlur={() => handleBlur('secondLanguage')}
                                /> Bengali
                            </span>
                        </FlexWrapper>
                        {
                            errors && errors.secondLanguage
                                ? <Error>
                                    * This field cannot be empty
                                    </Error>
                                : null
                        }
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <Label>Father's Name</Label>
                    </Col>
                    <Col md={8} xs={12}>
                        <Input
                            type="text"
                            name="fatherName"
                            placeholder="Full name of the father"
                            value={formData.fatherName || ''}
                            onChange={onInputChange}
                            onBlur={() => handleBlur('fatherName')}
                        />
                        {
                            errors && errors.fatherName
                                ? <Error>
                                    * This field cannot be empty
                                    </Error>
                                : null
                        }
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <Label>Father's Occupation</Label>
                    </Col>
                    <Col md={8} xs={12}>
                        <Input
                            type="text"
                            name="fatherOccupation"
                            placeholder="Occupation of the father"
                            value={formData.fatherOccupation || ''}
                            onChange={onInputChange}
                            onBlur={() => handleBlur('fatherOccupation')}
                        />
                        {
                            errors && errors.fatherOccupation
                                ? <Error>
                                    * This field cannot be empty
                                    </Error>
                                : null
                        }
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <Label>Mother's Name</Label>
                    </Col>
                    <Col md={8} xs={12}>
                        <Input
                            type="text"
                            name="motherName"
                            placeholder="Full name of the mother"
                            value={formData.motherName || ''}
                            onChange={onInputChange}
                            onBlur={() => handleBlur('motherName')}
                        />
                        {
                            errors && errors.motherName
                                ? <Error>
                                    * This field cannot be empty
                                    </Error>
                                : null
                        }
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <Label>Phone Number</Label>
                    </Col>
                    <Col md={8} xs={12}>
                        <Input
                            type="text"
                            name="phoneNo"
                            placeholder="Phone number of the parents"
                            value={formData.phoneNo || ''}
                            onChange={onInputChange}
                            onBlur={() => handleBlur('phoneNo')}
                        />
                        {
                            errors && errors.phoneNo
                                ? <Error>
                                    * This field cannot be empty
                                    </Error>
                                : null
                        }
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <Label>Alternate Phone Number</Label>
                    </Col>
                    <Col md={8} xs={12}>
                        <Input
                            type="text"
                            name="alternateNo"
                            placeholder="Alternate phone number (if available)"
                            value={formData.alternateNo || ''}
                            onChange={onInputChange}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <Label>Email</Label>
                    </Col>
                    <Col md={8} xs={12}>
                        <Input
                            type="email"
                            name="email"
                            placeholder="Email for correspondence"
                            value={formData.email || ''}
                            onChange={onInputChange}
                        />
                        {
                            errors && errors.acquiredBy
                                ? <Error>
                                    * This field cannot be empty
                                    </Error>
                                : null
                        }
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <Label>Address for Correspondence</Label>
                    </Col>
                    <Col md={8} xs={12}>
                        <Textarea
                            name="address"
                            rows="5"
                            value={formData.address || ''}
                            onChange={onInputChange}
                            onBlur={() => handleBlur('address')}
                        />
                        {
                            errors && errors.address
                                ? <Error>
                                    * This field cannot be empty
                                    </Error>
                                : null
                        }
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <Label>Pincode</Label>
                    </Col>
                    <Col md={8} xs={12}>
                        <Input
                            type="text"
                            name="pincode"
                            value={formData.pincode || ''}
                            onChange={onInputChange}
                            onBlur={() => handleBlur('pincode')}
                        />
                        {
                            errors && errors.pincode
                                ? <Error>
                                    * This field cannot be empty
                                    </Error>
                                : null
                        }
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <Label>Name of the school last attended</Label>
                    </Col>
                    <Col md={8} xs={12}>
                        <Input
                            type="text"
                            name="lastSchool"
                            placeholder="Full name of the last school attended"
                            value={formData.lastSchool || ''}
                            onChange={onInputChange}
                            onBlur={() => handleBlur('lastSchool')}
                        />
                        {
                            errors && errors.lastSchool
                                ? <Error>
                                    * This field cannot be empty
                                    </Error>
                                : null
                        }
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <Label>UID No</Label>
                    </Col>
                    <Col md={8} xs={12}>
                        <Input
                            type="text"
                            name="uid"
                            placeholder="Unique identification number from the Board"
                            value={formData.uid || ''}
                            onChange={onInputChange}
                            onBlur={() => handleBlur('uid')}
                        />
                        {
                            errors && errors.uid
                                ? <Error>
                                    * This field cannot be empty
                                    </Error>
                                : null
                        }
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <Label>Courses in which Admission is sought for</Label>
                    </Col>
                    <Col md={8} xs={12}>
                        <FlexWrapper style={{ height: '40px' }}>
                            <span>
                                <input
                                    type="radio"
                                    name="stream"
                                    className="mr-1"
                                    value="Humanities"
                                    checked={formData.stream && formData.stream === 'Humanities'}
                                    onChange={onInputChange}
                                    onBlur={() => handleBlur('stream')}
                                /> HUMANITIES
                            </span>
                            <span className="ml-md-5 ml-3">
                                <input
                                    type="radio"
                                    name="stream"
                                    className="mr-1"
                                    value="Commerce"
                                    checked={formData.stream && formData.stream === 'Commerce'}
                                    onChange={onInputChange}
                                    onBlur={() => handleBlur('stream')}
                                /> COMMERCE
                            </span>
                        </FlexWrapper>
                        {
                            errors && errors.stream
                                ? <Error>
                                    * This field cannot be empty
                                    </Error>
                                : null
                        }
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} xs={12}>
                        <Label>If the child is suffering from any illness, please give detail</Label>
                    </Col>
                    <Col md={8} xs={12}>
                        <Textarea
                            name="illness"
                            rows="5"
                            value={formData.illness || ''}
                            onChange={onInputChange}
                        />
                    </Col>
                </Row>
                <div className="mr-3 mt-5 mb-5">
                    <p>
                        The following certificate are to be obtained by parent/guardian and emailed to the school at <a href="mailto:greenlawnschool.soureni@gmail.com">greenlawnschool.soureni@gmail.com</a>
                    </p>
                    <ul>
                        <li>Passport photo</li>
                        <li>A transfer certificate signed by Principal/Headmistress of the school which the child last attended</li>
                        <li>Self attested copy of Aadhar</li>
                        <li>Attested copy of Class X Marksheet</li>
                        <li>Birth Certificate</li>
                    </ul>
                </div>
                {renderFormMessage()}
                <div className="w-100 text-center">
                    <Submit
                        disabled={isButtonDisabled}
                        type="submit"
                    >
                        SUBMIT
                </Submit>
                </div>
            </form>
        </div>
    )
}

export default AdmissionForm