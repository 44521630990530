import React from 'react';
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'
import facebookLogo from '../images/facebook_logo.png'
import whatsappLogo from '../images/whatsapp.svg'
import { Container } from './Styles';

const Wrapper = styled.div`
    background-color: #1b5e20;
    color: #fff;
    width: 100%;
    min-height: 200px;
    @media (max-width: 768px) {
        height: auto;
        padding: 0;
    }
`;

const Footer = (props) => {
    return (
        <Wrapper>
            <Container>
                <Row className="m-0 p-0">
                    <Col xs={6} md={4}>
                        <LeftSection>
                            <p>
                                <b>Address:</b> <br />
                                Green Lawn School <br />
                                Soureni Bazar <br />
                                P.O Soureni - 734227 <br />
                                District: Darjeeling
                        </p>
                        </LeftSection>
                    </Col>
                    <Col xs={6} md={4}>
                        <MiddleSection>
                            <p>
                                <b>Contact:</b><br />
                                +91-8250499503<br />
                                +91-9563485911<br />
                                +91-7029683089
                            </p>
                        </MiddleSection>
                    </Col>
                    <Col xs={12} md={4}>
                        <RightSection>
                            <p className={window.innerWidth > 769 ? 'text-right' : 'text-left'}>
                                <b>Connect with us at:</b><br />
                                <a href="https://www.facebook.com/Green-Lawn-School-1658966741043672/" target="_blank" rel="noopener noreferrer" ><img src={facebookLogo} width="35" height="35" alt="Facebook" /></a>
                                <img src={whatsappLogo} width="35" height="35" alt="Whatsapp" className="ml-3"/>
                            </p>
                        </RightSection>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    )
}


const LeftSection = styled.div`
    @media (max-width: 768px) {
        padding: 1rem 1rem 0 1rem;
    }
`;
const MiddleSection = styled.div`
    width: 150px;
    margin: 0 auto;
    @media (max-width: 768px) {
        width: 100%;
        padding: 1rem 0 0 0;
    }
`;
const RightSection = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    @media (max-width: 768px) {
        display: block; 
        justify-content: none;
        padding: 0 1rem 1rem 1rem;
    }
`;


export default Footer