import React from 'react'
import styled from 'styled-components'
import Form from '../../components/AdmissionForm'
import { WrapperContainer, Container, Section } from '../../components/Styles'

const FormWrapper = styled.div`
    margin: 5rem;
    background-color: #fff;
    padding: 2rem;
    @media(max-width: 768px) {
        padding: 1rem;
        margin: 0;
    }
`




const AdmissionsPage = (props) => {
    return <div style={{ minHeight: '800px' }}>
        <WrapperContainer >
            <Section background="#C8E6C9">
                <Container>
                    <h1 className="text-center">Admissions</h1>
                    <p className="text-center" style={{ fontSize: window.innerWidth >= 769 ? '1.2rem' : '1rem' }}>
                        Application for registration and admission for classes K.G. to VIII are from September and for Class XI from April. Formal admission is completed only after the application form has been duly filled in and submitted, a written test and an interview is conducted and the necessary fees are paid. The Principal reserves the right to refuse admission to any pupils’ without furnishing any reasons. No pupil will be admitted to the School without a Birth Certificate, a Transfer Certificate from the school last attended and a Medical Certificate stating that the child is fit for hilly climate.
                        </p>
                </Container>
            </Section>
            <Section background="#f6f6f6">
                <Container>
                    <h2 className="text-center">Application Form for Class XI</h2>
                    <FormWrapper>
                        <Form />
                    </FormWrapper>
                </Container>
            </Section>
        </WrapperContainer>
    </div>
}

export default AdmissionsPage