import styled from 'styled-components';

const WrapperContainer = styled.div`
    width: 100%;
    margin-top: 80px;
`;

const Section = styled.section`
    padding: 3rem;
    background-color: ${(props) => props.background ? props.background : '#fff'};
    @media (max-width: 768px) {
        padding: 0.5rem;
    }
`;

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    @media (min-width: 576px) 
    {
        max-width: 540px;  
    }
    @media (min-width: 768px) {
        max-width: 720px; 
    }
    @media (min-width: 992px) {
        max-width: 960px; 
    }
    @media (min-width: 1200px) {
        max-width: 1280px;
    }
`;

const FlexWrapper = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    height: ${(props) => props.height ? props.height : '100%'};
    display: flex;
    align-items: center;
`

export {
    WrapperContainer,
    Container,
    Section,
    FlexWrapper
} 