import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'
import { Link } from 'react-router-dom'
import logo from '../images/logo.png'
import { Container } from './Styles';
import hamburgerIcon from '../images/hamburger_icon.svg'

const Header = (props) => {

    const [slider, showSlider] = useState(false)
    const sliderRef = useRef(slider);

    useEffect(() => {
        document.addEventListener('click', (e) => {
            const slider = sliderRef.current;
            if ((e.target.id !== 'nav-btn' && e.target.id !== 'nav-img') && slider) {
                showSlider(false)
            }
        }, false);

        return () => {
            document.removeEventListener('click')
        }
    }, [])


    return (
        <Wrapper id="nav" show={slider}>
            <Container>
                <Row className="m-0 p-0">
                    <Col md={6} xs={12}>
                        <LeftSection>
                            <Link to="/">
                                <Logo src={logo} alt="logo" />
                            </Link>
                            <div className="header-title-wrapper">
                                <Title>Green Lawn School</Title>
                                <Subtitle>
                                    Affliated to Council For The Indian School Certificate Examinations, New Delhi
                                </Subtitle>
                            </div>
                            <div className="nav-btn">
                                <NavBtn id="nav-btn" onClick={() => {
                                    sliderRef.current = !slider;
                                    showSlider(!slider)
                                }}>
                                    <img id="nav-img" src={hamburgerIcon} height="40" width="40" alt="menu" />
                                </NavBtn>
                            </div>
                        </LeftSection>
                        <SliderWrapper id="slider-menu" show={slider}>
                            <NavMenu mobile>
                                <MobileNavItem>
                                    <Link to="/">Home</Link>
                                </MobileNavItem>
                                <MobileNavItem>
                                    <Link to="/admissions">Admissions</Link>
                                </MobileNavItem>
                                <MobileNavItem>
                                    <Link to="/covid-guidelines">Covid-19 Guidelines</Link>
                                </MobileNavItem>
                            </NavMenu>
                        </SliderWrapper>
                    </Col>
                    <Col md={6} xs={0}>
                        <RightSection>
                            <NavMenu>
                                <NavItem>
                                    <Link to="/">Home</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/admissions">Admissions</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/covid-guidelines">Covid-19 Guidelines</Link>
                                </NavItem>
                            </NavMenu>
                        </RightSection>
                    </Col>
                </Row>
            </Container>
        </Wrapper >
    )
}

const Logo = styled.img`
    height: 80px;
`;

const Title = styled.div`
    font-size: 2rem;
    font-weight: bold;
    color: #33691e;
    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

const Subtitle = styled.div`
    font-size: 0.9rem;
    color: #c5224e;
    font-weight: bold;
    word-wrap: break-word;
    @media (max-width: 768px) {
        font-size: 0.7rem;
        width: 100%;
        
    }
`;

const Wrapper = styled.header`
    position: fixed;
    height: ;
    top: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    z-index: 5;
    height: 80px;
    // @media(max-width: 768px) {
    //     height: ${(props) => props.slider ? '200px' : '140px'};
    // }
`;

const LeftSection = styled.div`
    display: flex;
    align-items: center;
    .nav-btn {
        @media(min-width: 769px) {
            display: none;
        }
    }
`;

const RightSection = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    float: right;
`;

const NavMenu = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 100%;
    ${(props) => props.mobile && css`
        display: block;
        height: auto;
  `}
`;

const NavItem = styled.li`
    margin: 1rem;
    display: inline-block;  
    vertical-align: middle;  
    padding: 0.5rem 1rem;;
    a {
        color: #000;
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
`;

const MobileNavItem = styled.li`
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
    height: 30px;
    font-size: 1rem;
    :hover {
        background-color: #33691e;
    }
    a {
        color: #000;
        text-decoration: none;
    }
    a:hover {
        color: #fff;
    }
`;

const NavBtn = styled.button`
    background: transparent;
    outline: none;
    border: none;
`
const SliderWrapper = styled.div`
    height: ${(props) => props.show ? '100px' : '0'};
    transition: all 0.2s;
    position: relative;
    overflow: hidden;
    background: #fff;
    width: 100px;
    float: right;
    margin-top: 0;
    opacity: 0.8;
`

export default Header