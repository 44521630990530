import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';
import { ReactPhotoCollage } from 'react-photo-collage';
import banner from '../../images/banner.jpg'
import SectionImg from '../../images/10.jpg'
import Image1 from '../../images/1.jpg'
import Image2 from '../../images/2.jpg'
import Image3 from '../../images/3.jpg'
import Image4 from '../../images/4.jpg'
import Image5 from '../../images/5.jpg'
import Image6 from '../../images/6.jpg'
import Image7 from '../../images/7.jpg'
import Image8 from '../../images/8.jpg'
import Image11 from '../../images/12.jpg'
import Image12 from '../../images/14.jpg'
import RevisedOnlineRoutine from '../../images/RevisedOnlineRoutine.pdf'
import LawniteFestiveWeek2020 from '../../images/LawniteFestiveWeek2020.pdf'
import VirtualSeriesNovemberSchedule from '../../images/VirtualSeriesNovemberSchedule.pdf'

import background1 from '../../images/background1.jpg'
import { Section, Container, WrapperContainer } from '../../components/Styles';

const setting = {
    width: '100%',
    height: ['550px', '270px', '270px'],
    layout: [1, 2, 3],
    photos: [
        { src: Image2 },
        { src: Image1 },
        { src: Image11 },
        { src: Image5 },
        { src: Image6 },
        { src: Image4 },
        { src: Image12 },
        { src: Image7 },
        { src: Image8 },
        { src: Image3 },
    ],
    showNumOfRemainingPhotos: true
};

const LandingPage = () => {
    return (
        <WrapperContainer>
            <div className="p-0 m-0">
                <Banner
                    src={banner}
                />
            </div>
            <Section>
                <Container>
                    <Row className="pt-md-5 pb-md-5 m-0">
                        <Col xs={12} md={6}>
                            <Intro>
                                <p>
                                    Green Lawn School is co-education school situated at Soureni, Mirik, District Darjeeling, at an altitude of 4500 ft. The environment is general, and the climate in particular, is conducive and salubrious to the development of sound and mind- the essence of education.
                                </p>
                                <p>
                                    The school offers CISCE curriculum and has classes from L.K.G to Class XII.
                                </p>
                            </Intro>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className="ml-md-5" style={{ overflow: 'hidden' }}>
                                <SectionImage src={SectionImg} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Section>

            {/* <NoticeSection>
                <Container className="h-100">
                    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                        <ShadowedPanel>
                            <h2 className="text-center">ANNOUNCEMENTS</h2>
                            <ul>
                                <li>
                                    Lawnites Festive Week has been extended till 18th November, 2020 <span className="new">NEW</span>
                                </li>
                                <li>
                                    <a href={VirtualSeriesNovemberSchedule} target="_blank" rel="noopener noreferrer">CISCE Virtual Learning Series Schedule for November 2020</a>

                                </li>
                                <li>
                                    Lawnites Festive Week 2020. <a href={LawniteFestiveWeek2020} target="_blank" rel="noopener noreferrer">Click Here</a> for details
                                </li>
                                <li>
                                    Online classes revised Time Table. <a href={RevisedOnlineRoutine} target="_blank" rel="noopener noreferrer">Click Here</a>
                                </li>
                                <li>
                                    School office will re-open from 8th June, 2020. Office timings 9am - 1pm
                                </li>
                                <li>
                                    Online classes for LKG - UKG to begin from 1st June, 2020
                                </li>
                                <li>
                                    Online classes for 1 - 12 to begin from 20th April, 2020
                                </li>
                                <li>
                                    Due to the outbreak of Corona virus school is closed until further notice
                                </li>
                            </ul>
                        </ShadowedPanel>
                    </div>
                </Container>
            </NoticeSection> */}


            <Section style={{ backgroundColor: '#f6f6f6', paddingBottom: '5rem' }}>
                <Container>
                    <h2 className="text-center">STUDENT LIFE</h2>
                    <div>
                        <ReactPhotoCollage {...setting} />
                    </div>
                </Container>

            </Section>

        </WrapperContainer>
    )
}

const Intro = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 1.5rem;
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

const ShadowedPanel = styled.div`
    padding: 1rem ;
    background-color: #fff;
    box-shadow:  0px 0px 8px 0px rgba(78,78,78,1);
    position: relative;
    width: 450px;
    margin: 0 auto;
    @media (max-width: 768px) {
        width: 300px;
    }
`;

const Banner = styled.img`
    width: 100%;
    display: block;
`;

const NoticeSection = styled.div`
    background: url(${background1}) no-repeat center center fixed;
    background-size: cover;
    padding: 3rem;
    height: 500px;
    @media (max-width: 768px) {
        padding: 3rem 1rem;
        font-size: 16px;
        height: auto;
    }
    .new {
        animation: blinker 1s linear infinite;
        background: linear-gradient(to right,#dc0101 0%,#7cc8dc 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
    }
    @keyframes blinker {
        50% {
          opacity: 0;
        }
      }
`;

const SectionImage = styled.img`
    width: 100%;
    height: auto;
    transition: all 1s;
    overflow: hidden;
    :hover {
        transform: scale(1.2);
    }
`;


export default LandingPage;